var exports = {};

exports = function (hljs) {
  return {
    aliases: ["console"],
    contains: [{
      className: "meta",
      begin: "^\\s{0,3}[\\w\\d\\[\\]()@-]*[>%$#]",
      starts: {
        end: "$",
        subLanguage: "bash"
      }
    }]
  };
};

export default exports;